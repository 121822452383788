import React from 'react';
import {
  FormDataConsumer,
  ImageField,
  ImageInput,
  Labeled,
  required,
  TextField,
  useInput,
  useRecordContext,
} from 'react-admin';
import { MAX_FILE_SIZE } from 'utils/consts';
import { ALLOWED_BADGE_FILE_TYPES } from '../index';
import PropTypes from 'prop-types';

const BadgeImageFormGroup = ({ source, defaultValueSource, textFieldSource, label, helperText }) => {
  const record = useRecordContext();
  useInput({
    source: source,
    defaultValue: record.badge[defaultValueSource],
  });

  return (
    <>
      <FormDataConsumer>
        {props => (
          <Labeled label='Image File Name'>
            <TextField source={textFieldSource} {...props} />
          </Labeled>
        )}
      </FormDataConsumer>
      <ImageInput
        name={source}
        source={source}
        label={label}
        accept={ALLOWED_BADGE_FILE_TYPES.join(',')}
        multiple={false}
        maxSize={MAX_FILE_SIZE}
        validate={[required()]}
        labelSingle='Drop an image here, or click to select an image to attach.'
        helperText={helperText}
      >
        <ImageField source='src' title='title' />
      </ImageInput>
    </>
  )
}

BadgeImageFormGroup.propTypes = {
  source: PropTypes.string.isRequired,
  defaultValueSource: PropTypes.string.isRequired,
  textFieldSource: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
}

export default BadgeImageFormGroup;
