import React from 'react';
import { Resource, useRecordContext } from 'react-admin';
import SecuredBadgeCreate from './BadgeCreate';
import SecuredBadgeEdit from './BadgeEdit';
import SecuredBadgeList from './BadgeList';
import VerifiedIcon from '@mui/icons-material/Verified';

export const BadgeIcon = VerifiedIcon;

// Converted to Mime types '.jpg,.jpeg,.png'
export const ALLOWED_BADGE_FILE_TYPES = ['image/jpeg', 'image/png'];

export const BadgeTitle = () => {
  const record = useRecordContext();
  return <span>Badge {record ? `- ${record?.badge?.name}` : ''}</span>;
};

export default (
  <Resource
    name='AdminBadgeTemplate'
    list={SecuredBadgeList}
    edit={SecuredBadgeEdit}
    create={SecuredBadgeCreate}
    icon={BadgeIcon}
    options={{ label: 'Badges' }}
  />
);
